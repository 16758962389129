import * as React from 'react'
import {Component} from "react";
import {SidebarOuter} from "../Workflows/SideBar";
import uuid from "uuid";
import {IDialogFlowIntentProps, IDialogFlowPhraseProps} from "./Index";
import {IWorkflowProps} from "../Workflows/Bootstrap";

export class Listing extends Component {

    public state = {
        "intents": [],
        "selectedIntent": {name: '', phrases: [], id: 0},
        "workflows": [],
        "selectedWorkflowId": 0
    };

    componentDidMount(): void {
        fetch('http://engage.everlytic.net/get_intents_page_data.php')
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        'intents': data.intents,
                        'selectedIntent': data.intents[0],
                        'workflows': data.workflows
                    })
                }
            );
    }

    addTextOption() {
        // @ts-ignore
        this.state.selectedIntent.phrases.push({text: ""})
        this.setState({'selectedIntent': this.state.selectedIntent});
    }

    createNewIntent() {
        let newIntent: IDialogFlowIntentProps = {
            id: uuid.v4(),
            name: "New Intent",
            phrases: [{
                text: ""
            }]
        };

        // @ts-ignore
        this.state.intents.unshift(newIntent);


        this.setState({
            'intents': this.state.intents,
            'selectedIntent': this.state.intents[0]
        });
    }

    selectIntent(intent: IDialogFlowIntentProps) {
        this.setState({'selectedIntent': intent})
    }

    save() {
        fetch('http://engage.everlytic.net/create_intent.php', {
            method: 'POST',
            body: JSON.stringify({
                intent: this.state.selectedIntent,
                workflowId: this.state.selectedWorkflowId
            })
        })
            .then(res => res.json())
            .then((intent : IDialogFlowIntentProps)  => {
                alert("Your intent has been added/updated successfully");
                this.state.selectedIntent.id = intent.id;
                this.selectIntent(this.state.selectedIntent);
            });
    }

    delete() {
        fetch('http://engage.everlytic.net/delete_intent.php?id=' + this.state.selectedIntent.id).then(() => {
            alert('deleted');
        });

        for (var i = 0; i < this.state.intents.length; i++) {
            var intent : IDialogFlowIntentProps = this.state.intents[i];

            if (this.state.selectedIntent.id === intent.id) {
                this.state.intents.splice(i, 1);
            }
        }

        this.setState({
            'intents': this.state.intents,
            'selectedIntent': this.state.intents[0]
        });
    }

    render() {
        let {intents, workflows} = this.state;
        return (
            <>
                <SidebarOuter>
                    <button className="btn m0 fr" onClick={this.createNewIntent.bind(this)}>Create</button>
                </SidebarOuter>
                <div>
                    {intents.map((intent : IDialogFlowIntentProps, index) => {
                        return (
                            <div className="list-item" key={index} onClick={(event: React.MouseEvent<HTMLElement>) => {
                                this.selectIntent(intent);
                            }}>
                                {intent.name}
                            </div>
                        )
                    })}
                </div>
                {this.state.selectedIntent.id !== 0 &&
                <div className="list-edit">
                    <div className="header">
                        <input
                            type="text"
                            className="form-controls"
                            value={this.state.selectedIntent.name}
                            onChange={(event) => {
                                this.state.selectedIntent.name = event.target.value;
                                this.setState({'selectedIntent': this.state.selectedIntent});
                            }}
                        />
                        <button className="btn bg-red" onClick={this.delete.bind(this)}>
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                    <label className="ml50 mb5">Intent actions workflow</label>
                    <br />
                    <select className="form-controls ml50 mb15" onChange={(event) => {
                        this.setState({'selectedWorkflowId': event.target.value});
                    }}>
                        <option>Select workflow</option>
                        {workflows.map((workflow: IWorkflowProps) => (
                            <option value={workflow.id} selected={this.state.selectedIntent.id === workflow.intent_id}>
                                {workflow.name}
                            </option>
                        ))}
                    </select>
                    <br />
                    <label className="ml50 mb5">Training phases</label>
                    <br />
                    {this.state.selectedIntent.phrases.map((phrase: IDialogFlowPhraseProps, index) => (
                        <div
                            key={this.state.selectedIntent.id + index} >
                            <input
                                type="text"
                                className="form-controls mb15 ml50"
                                onChange={(event) => {
                                    phrase.text = event.target.value;
                                }}
                                defaultValue={phrase.text}
                            />
                        </div>
                    ))}
                    <i className="fas fa-plus ml50 btn fl" onClick={this.addTextOption.bind(this)} />
                    <button className="btn bg-green fl" onClick={this.save.bind(this)}>Save</button>

                </div>
                }
            </>
        )
    }
}
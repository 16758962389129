import {IChart} from "@mrblenny/react-flow-chart/src";

export const EverlyticVoiceIvrAdapter = (chart: IChart): object => {
    let payload = {};

    for (let key in chart.nodes) {
        // @ts-ignore
        payload[key] = {options: {}}
        if (chart.nodes[key].properties.type === 'message') {
            // @ts-ignore
            payload[key]['say'] = chart.nodes[key].properties.value;
        }

        if (chart.nodes[key].properties.type === 'api') {
            // @ts-ignore
            payload[key]['callback'] = chart.nodes[key].properties.value;
        }

        let keypess = 0;
        for (let linkKey in chart.links) {
            if (chart.links[linkKey].from.nodeId === chart.nodes[key].id) {
                keypess++;
                // @ts-ignore
                if (chart.links[linkKey].hasOwnProperty('to') && chart.links[linkKey].to.nodeId.length > 0) {
                    // @ts-ignore
                    payload[key].options[keypess] = chart.links[linkKey].to.nodeId;
                }
            }
        }
    }
    return payload;
}




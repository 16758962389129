import {INode} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement, SyntheticEvent} from "react";
import uuid from "uuid";
import styled from "styled-components";

const AddNewPort = styled.div`
    padding: 5px 0px;
    font-size: 13px;
    text-align: center;
    color: #656666;
`

export const AddPort = (props : {node: INode, parent: any}): ReactElement => {

    const handleClick = (event: SyntheticEvent) => {
        event.preventDefault();
        const uniqueId = uuid.v4()

        // @ts-ignore
        let x = props.node.size.width;

        props.node.ports[uniqueId] = {
            id: uniqueId,
            type: 'right',
            properties: {
                value: 'yes',
            },
            position: {
                x: x,
                y: props.parent.current.clientHeight - 12
            }
        }
    }

    if (props.node.properties.type !== 'message') {
        return <></>
    }

    return (
        <AddNewPort>
            <a href="#add_new" onClick={handleClick}><i className="fas fa-plus" /></a>
        </AddNewPort>
    )
}
import React, {ReactElement} from 'react';
import {Header} from "./components/Header";
import {Page} from "./components/Page";
import {Menu} from "./components/Menu";
import {Route, BrowserRouter} from 'react-router-dom';
import {Listing as IntentListing} from "./components/Intents/Listing";
import {Listing as WorkflowListing} from './components/Workflows/Listing';

import "./App.css"
import {WorkflowBuilder} from "./components/Workflows/WorkflowBuilder";

const App = (): ReactElement => (
    <BrowserRouter>
        <div className="App">
            <Header/>
            <Page>
                <Menu />
                <Route exact path="/workflows" component={WorkflowListing} />
                <Route path="/workflows/:id" component={WorkflowBuilder} />
                <Route exact path="/intents" component={IntentListing} />
                <Route exact path="/">
                    <img src="/messenger.png" />
                </Route>
            </Page>
        </div>
    </BrowserRouter>
)

export default App;
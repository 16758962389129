import styled from "styled-components";
import {IPortDefaultProps} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";

const PortOuter = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid #dadada;
  &:hover > div {
    background: cornflowerblue;
  }
`

const PortInner = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background:  ${(props) => props.active ? 'cornflowerblue' : 'grey' };
  cursor: pointer;
`

export const Port = ({ isLinkSelected, isLinkHovered, port }: IPortDefaultProps) : ReactElement => (
    <PortOuter key={port.id}>
        <PortInner active={isLinkSelected || isLinkHovered}/>
    </PortOuter>
)
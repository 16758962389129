import {INodeInnerDefaultProps} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";

export const ApiNodeInner = ({ node, config }: INodeInnerDefaultProps): ReactElement => (
    <div className="node-inner">
        <div className="mb10">
            <label>Endpoint:</label>
            <input
                type="text"
                onChange={(event) => {
                    node.properties.endpoint = event.target.value
                }}
                onClick={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={node.properties.endpoint}
            />
        </div>
        <div className="mb10">
            <label>Verb:</label>
            <select
                defaultValue={node.properties.verb}
                onChange={(event) => {
                    node.properties.verb = event.target.value
                }}>
                <option>GET</option>
                <option>POST</option>
                <option>PUT</option>
                <option>DELETE</option>
            </select>
        </div>
        <div className="mb10">
            <label>Capture result into variable:</label>
            <input
                type="text"
                onChange={(event) => {
                    node.properties.variable_name = event.target.value
                }}
                onClick={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={node.properties.variable_name}
            />
        </div>
    </div>
)


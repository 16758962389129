import {INode, INodeInnerDefaultProps} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";
import {MessageNodeInner} from "./Inners/MessageNodeInner";
import {UserInputNodeInner} from "./Inners/UserInputNodeInner";
import {ApiNodeInner} from "./Inners/ApiNodeInner";

export const  NodeInnerFactory = ({ node, config }: INodeInnerDefaultProps): ReactElement  => {
    if (node.properties.type === 'message') {
        return <MessageNodeInner config={config} node={node}/>
    }

    if (node.properties.type === 'user-input') {
        return <UserInputNodeInner config={config} node={node}/>
    }

    if (node.properties.type === 'api') {
        return <ApiNodeInner config={config} node={node}/>
    }

    return <></>
}
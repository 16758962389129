import React, {ReactElement} from "react";
import {INode} from "@mrblenny/react-flow-chart/src";
import styled from "styled-components";

const DeleteNodeOuter = styled.div`
    float: right;
    & a {
        color: #a6a6a6;
        :hover {
            color: #d72222;
        }
    }
    & i {
        font-size: 20px;
    }   
`

export const DeleteNodeButton = (props:{node: INode}): ReactElement => {
    if (props.node.id === 'start') {
        return <></>
    }

    return (
        <DeleteNodeOuter>
            <a href="">
                <i className="fas fa-times-circle"></i>
            </a>
        </DeleteNodeOuter>
    )
}

import React, {SyntheticEvent} from "react";
import {INode, INodeDefaultProps} from "@mrblenny/react-flow-chart/src";
import styled from "styled-components";
import {AddPort} from "./Ports/AddPort";
import {NodeHeader} from "./Nodes/NodeHeader";

const NodeOuter = styled.div`
    position: absolute;
    background: #FFFFFF;
    color: black;
    border-radius: 10px;
    border: 1px solid #cdcdcd;
    box-shadow: 0 0 15px -3px rgba(148,148,148,.1);
    width:300px;
`

export const Node = React.forwardRef(
    ({ node, children, ...otherProps }: INodeDefaultProps, ref: any) => {
    return (
        <NodeOuter ref={ref} {...otherProps} className={node.properties.linkHovered}>
            <NodeHeader node={node}/>
            {children}
            <AddPort node={node} parent={ref}/>
        </NodeOuter>
    )
})


import {IChart} from "@mrblenny/react-flow-chart/src/types/chart";
import {cloneDeep} from "lodash";

export const ChartSerialiser = (chart: IChart): string => {
    let newChart = cloneDeep(chart);
    for (var key in newChart.nodes) {
        if (newChart.nodes.hasOwnProperty(key)) {
            // @ts-ignore
            delete newChart.nodes[key].position.node;
        }
    }
    return JSON.stringify(newChart);
};
import {SidebarOuter} from "./SideBar";
import * as React from "react";
import {Component} from "react";
import {IWorkflowProps} from "./Bootstrap";
import {CreateWorkflow} from "./CreateWorkflow";
import {Link} from "react-router-dom";

export class Listing extends Component {
    public state = {
        workflows: []
    };

    componentDidMount(): void {
        this.setWorkflowsState();
    }

    setWorkflowsState() {
        fetch('http://engage.everlytic.net/get_workflows.php')
            .then((res => res.json()))
            .then(workflows => this.setState({workflows: workflows}));
    }

    render() {
        return (
            <>
                <SidebarOuter>
                    <CreateWorkflow />
                </SidebarOuter>
                <div>
                    {this.state.workflows.length
                        ?
                        this.state.workflows.map((workflow: IWorkflowProps, index: any) => {
                            return (
                                <Link key={index} to={'workflows/' +workflow.id}>
                                    <div className="list-item" key={index}>
                                        {workflow.name}
                                    </div>
                                </Link>
                            )
                        })
                        : <div className="list-item ">No Workflows found</div>
                    }
                </div>
            </>
        )
    }
}



import styled from "styled-components";
import {INodeInnerDefaultProps} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement, useState} from "react";

const Outer = styled.div`
  padding: 20px 20px 10px 20px;
  font-size: 14px;
`

export const MessageNodeInner = ({ node, config }: INodeInnerDefaultProps): ReactElement => {

    const [showAttachment, setShowAttachment] = useState(node.properties.attachmentUrl);

    return (
        <Outer>
            <textarea
                className="composeMessage"
                onChange={(event) => { node.properties.value = event.target.value}}
                onClick={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue ={node.properties.value}
            ></textarea>
            <span className="add-attachment">
                {showAttachment
                    ? <input
                        type="text"
                        placeholder="Attachment Url"
                        onClick={(e) => e.stopPropagation()}
                        onMouseUp={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        onChange={(event) => { node.properties.attachmentUrl = event.target.value}}
                        defaultValue={node.properties.attachmentUrl}
                    />
                    : <a
                        onClick={() => setShowAttachment(true)}>
                        Add attachment
                    </a>
                }</span>
        </Outer>
    )
}

import styled from "styled-components";
import {IPortsGroupDefaultProps} from "@mrblenny/react-flow-chart/src";

export const PortsGroupRight = styled.div<IPortsGroupDefaultProps>`
    display: flex;
    justify-content: right;
    flex-direction: column;
    width: 100%;
`

export const PortsGroupTop = styled.div<IPortsGroupDefaultProps>`
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: left;
    width: 100%;
    left: 0;
    top: 12px;
    flex-direction: row;
    > div {
      margin: 0 3px;
    }
`

export const PortsGroupOuter = styled.div<IPortsGroupDefaultProps>`
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    top: 0;
    right: -8px;
    flex-direction: column;
    > div {
      margin: 3px 0;
    }
`
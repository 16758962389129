import {IPortsDefaultProps, IPortsGroupDefaultProps, PortsGroupDefault} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";
import {OptionsPort} from "./OptionPort";
import {PortsGroupOuter, PortsGroupRight, PortsGroupTop} from "./PortGroup";

const optionPortFormatter = (child: ReactElement<any>, i: number): ReactElement<any> => (
    <OptionsPort key={i} obj={child} index={i + 1} />
)

export const Ports = ({ children, config }: IPortsDefaultProps) => {
    return (
        <div>
            <PortsGroupTop config={config} side="top">
                {children.filter((child) => ['input', 'top'].includes(child.props.port.type))}
            </PortsGroupTop>
            <PortsGroupOuter config={config} side="bottom">
                {children.filter((child) => ['output', 'bottom'].includes(child.props.port.type))}
            </PortsGroupOuter>
            <PortsGroupRight config={config} side="right">
                {children.filter((child) =>
                    ['right'].includes(child.props.port.type)).map(optionPortFormatter)}
            </PortsGroupRight>
        </div>
    )
}
import * as React from 'react'
import styled from 'styled-components'
import {SidebarItem} from "./SideBarItem";
import {ReactElement} from "react";
import {IChart} from "@mrblenny/react-flow-chart/src/types/chart";

import {ChartSerialiser} from "../Chart/ChartSerialiser";
import {EverlyticVoiceIvrAdapter} from "../../EverlyticVoiceIvrAdapter";

export const SidebarOuter = styled.div`
  width: 330px;
  background: #f3f4f5;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid #d3d3d3;
  padding: 30px;
  height: 100vh;
  box-shadow: inset 6px 0px 18px -9px rgba(0, 0, 0, 0.21);
  background: url(/bg.jpg)
`

export const SideBar = (props : {chart: IChart, id: number}): ReactElement => {

    const saveWorkflow = () => {
        fetch('http://engage.everlytic.net/update_workflow.php', {
            method: "POST",
            body: JSON.stringify({
                'chart': ChartSerialiser(props.chart),
                'id': props.id
            })
        }).then((res => alert('saved')));
    }

    const createTestCall = () => {
        fetch('http://engage.everlytic.net/createCall.php', {
            method: 'POST',
            mode: 'no-cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(EverlyticVoiceIvrAdapter(props.chart))
        }).then(() => alert('complete'));
    }

    const createTestWhatsapp = () => {
        fetch('http://engage.everlytic.net/SendWhatsApp.php?workflowId=' + props.id, {
            method: 'POST',
            mode: 'no-cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(EverlyticVoiceIvrAdapter(props.chart))
        }).then(() => alert('complete'));
    }

    return(
        <SidebarOuter>
            <SidebarItem
                type="Message"
                icon="fas fa-comment-alt"
                ports={{
                    messagePortTop : {
                        id: 'messagePortTop',
                        type: 'top',
                        properties: {
                            custom: 'property',
                        },
                    },
                }}
                properties={{
                    custom: 'property',
                    type: 'message'
                }}
            />
            <SidebarItem
                type="API Call"
                icon="fas fa-broadcast-tower"
                properties={ {
                    custom: 'property',
                    type: 'api'
                }}
                ports={{
                    apiPortTop: {
                        id: 'apiPortTop',
                        type: 'top',
                        properties: {
                            custom: 'property',
                        },
                    },
                    apiPortOut : {
                        id: 'apiPortOut',
                        type: 'output',
                        properties: {
                            custom: 'property',
                        },
                    }
                }}
            />
            <SidebarItem
                type="User Input"
                icon="fas fa-hashtag"
                properties={{
                    custom: 'property',
                    type: 'user-input'
                }}
                ports={{
                    userInputPortTop: {
                        id: 'userInputPortTop',
                        type: 'top',
                        properties: {
                            custom: 'property',
                        },
                    },
                    userInputPortOut : {
                        id: 'userInputPortOut',
                        type: 'output',
                        properties: {
                            custom: 'property',
                        },
                    }
                }}
            />
            <SidebarItem
                type="Bridge"
                icon="fas fa-phone-volume"
                properties={{
                    custom: 'property',
                    type: 'bridge'
                }}
                ports={{
                    bridgePortTop: {
                        id: 'bridgePortTop',
                        type: 'top',
                        properties: {
                            custom: 'property',
                        },
                    },
                }}
            />
            <button className="btn mt25 w-100 " onClick={saveWorkflow}>Save</button>
            <button className="btn bg-green mt25 w-100" onClick={createTestCall}>Test voice call</button>
            <button className="btn bg-red mt25 w-100" onClick={createTestWhatsapp}>Test Whatsapp</button>
        </SidebarOuter>
    )
}

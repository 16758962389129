import React, {ReactElement} from "react";
import styled from "styled-components";
import {INode} from "@mrblenny/react-flow-chart/src";
import {StartNodeIdentifier} from "./StartNodeIndentifier";
import {DeleteNodeButton} from "./DeleteNodeButton";

const NodeHeaderOuter = styled.div`
    padding: 10px;
    color: #656666;
    font-weight: 'bold';
    border-radius: 10px 10px 0px 0px;
    text-transform: capitalize;
    position: relative;
    clear:both;    
`

export const NodeHeader = (props: {node :INode}): ReactElement => {
    return (
        <NodeHeaderOuter className={props.node.properties.type}>
            <span>{props.node.properties.type}</span>
            <StartNodeIdentifier node={props.node}/>
            <DeleteNodeButton node={props.node}/>
        </NodeHeaderOuter>
    )
}

import styled from "styled-components";
import React, {ReactElement} from "react";

const OptionPort = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    position:absolute;
    right: -8px 
`

const OptionsPortOuter = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: center;
    color: #60b2fe;
    border: 1px solid #CFCFCF;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding:5px;
    font-size: 14px;
`

export const OptionsPort = (props: {obj: any, index: any }): ReactElement => (
    <>
        <OptionsPortOuter>
            Replies with {props.index}
            <OptionPort>
                {props.obj}
            </OptionPort>
        </OptionsPortOuter>
    </>
);
import React, {Component,} from 'react';

import {FlowChart, IChart} from "@mrblenny/react-flow-chart/src";
import {Node} from "../Node";
import {Ports} from "../Ports/Ports";
import {Port} from "../Ports/Port";
import {Link} from "../Chart/Link";
import {chart} from "../../InitialChart";
import mapValues from "@mrblenny/react-flow-chart/src/container/utils/mapValues";
import * as actions from "../../Callbacks";
import {SideBar} from "./SideBar";
import {NodeInnerFactory} from "../Nodes/NodeInnerFactory";
import {cloneDeep} from "lodash";

type WorkflowBuilderProps = {
    match: any;
}

export class WorkflowBuilder extends Component<WorkflowBuilderProps> {

    public workflowId : number = this.props.match.params.id;

    public state : IChart = {
        hovered: {},
        links: {},
        nodes: {},
        offset: {y: 0, x: 0},
        properties: {},
        selected: {}
    };

    componentDidMount(): void {
        this.getWorkflow().then((workflow) => {
            (workflow.meta === null || workflow.meta.length === 0)
                ? this.setState(cloneDeep(chart))
                : this.setState(JSON.parse(workflow.meta));
        });
    }

    getWorkflow() {
        return fetch('http://engage.everlytic.net/get_workflow.php?id=' + this.workflowId).then(res => res.json())
    }

    render() {
        let chart : IChart = this.state;

        if (Object.values(chart.nodes).length === 0) {
            return <></>
        }

        let callbacks = mapValues(actions, (func: any) => (...args: any) =>
            this.setState(func(...args))) as typeof actions

        return (
            <>
                <SideBar chart={chart} id={this.workflowId}/>
                <FlowChart
                    chart={chart}
                    Components={{
                        Node: Node,
                        NodeInner: NodeInnerFactory,
                        Ports: Ports,
                        Port: Port,
                        Link: Link,
                    }}
                    callbacks={callbacks}
                />
            </>
        )
    }
}

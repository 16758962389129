import styled from "styled-components";
import {INode} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";

const StartNodeIdentifierOuter = styled.div`
    background: #64DD9B;
    color:#FFFFFF;
    float:right;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    position:relative;
    top:-2px;
    font-weight:300;

`

export const StartNodeIdentifier = (props: {node: INode}): ReactElement => {

    if (props.node.id !== 'start') {
        return <></>
    }

    return (
        <StartNodeIdentifierOuter>
            Starting Step
        </StartNodeIdentifierOuter>
    )
}
import {INodeInnerDefaultProps} from "@mrblenny/react-flow-chart/src";
import React, {ReactElement} from "react";

export const UserInputNodeInner = ({ node, config }: INodeInnerDefaultProps): ReactElement => (
    <div className="node-inner">
        <div className="mb10">
            <label>Input Validation:</label>
            <select
                defaultValue={node.properties.validation}
                onChange={(event) => {
                    node.properties.validation = event.target.value
                }}>
                <option>No validation</option>
                <option>Email</option>
                <option>Cellphone number</option>
                <option>Regualar expression</option>
            </select>
        </div>
        <div className="mb5">
            <label>Capture input into variable:</label>
            <input
                type="text"
                onChange={(event) => {
                    node.properties.variable_name = event.target.value
                }}
                onClick={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={node.properties.variable_name}
            />
        </div>
    </div>
)


import * as React from 'react'
import styled from 'styled-components'
import {INode, REACT_FLOW_CHART} from "@mrblenny/react-flow-chart/src";
import {ReactElement} from "react";

const Outer = styled.div`
  padding: 15px;
  font-size: 14px;
  background: white;
  color: #656666;
  cursor: move;
  margin-bottom: 15px;
  border: 1px solid #CCC;
  border-radius: 5px;
  box-shadow: 0 0 15px -3px rgba(148,148,148,.6);
  :hover {
    & i {
      color: #8fc644; 
    }
  }
    & i {
    padding-right: 10px;
    color: #7e7e7e;
  }
`

export interface ISidebarItemProps {
    type: string,
    ports: INode['ports'],
    properties?: any,
    icon: string
}

export const SidebarItem = ({ type, ports, properties, icon}: ISidebarItemProps): ReactElement => {
    return (
        <Outer
            draggable={true}
            onDragStart={ (event) => {
                event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }))
            } }
        >
            <i className={icon}/>
            {type}
        </Outer>
    )
}
import React, {ReactElement} from "react";

import styled from "styled-components";
import {Link} from "react-router-dom";

const MenuOuter = styled.ul`
    min-height: 100%;
    height: 100vh;
    background: #FFF;
    min-width:60px;
    margin:0px;
    padding:0px;
    display: flex;
    flex-direction: column;
    padding-top:30px;
    border-left: 3px solid #92c849;
    & li {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        list-style:none;
    }
    & li a {
        color: #8d8d8c;
        font-size: 26px;
        :hover {
            color: #8fc644;
        }
    } 
`

const MenuItem = (props: {icon: string, link: string}): ReactElement => (
    <li>
        <Link to={props.link}>
            <i className={'fas fa-' + props.icon}></i>
        </Link>
    </li>
)

export const Menu = (): ReactElement => (
    <MenuOuter>
        <MenuItem icon="comments" link="/"/>
        <MenuItem icon="magic" link="/workflows"/>
        <MenuItem icon="robot" link="/intents"/>
    </MenuOuter>
)


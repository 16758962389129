import {default as React, ReactElement} from "react";
import {SidebarOuter} from "./SideBar";
import {useHistory} from "react-router-dom";

export const CreateWorkflow = (): ReactElement => {
    const history = useHistory();

    const handleClick = async () => {
        const workflowName = prompt("Workflow name");

        await fetch('http://engage.everlytic.net/create_workflow.php', {
            'method': "POST",
            'body': JSON.stringify({
                name: workflowName
            })
        })
        .then(res => res.json())
        .then(workflow => history.push('/workflows/' + workflow.id));
    }

    return(
        <button className="btn m0 fr" onClick={handleClick}>Create</button>
    )
}
import { v4 } from 'uuid'

import {
    IChart, ILink, IOnCanvasClick, IOnCanvasDrop, IOnDeleteKey,
    IOnDragCanvas,
    IOnDragNode, IOnLinkCancel,
    IOnLinkComplete, IOnLinkMouseEnter, IOnLinkMouseLeave,
    IOnLinkMove,
    IOnLinkStart, IOnNodeClick, IOnNodeSizeChange, IOnPortPositionChange, IPosition
} from "@mrblenny/react-flow-chart/src";

export const onDragNode: IOnDragNode = ({ config, event, data, id }) => (chart: IChart) => {
    const nodechart = chart.nodes[id]

    if (nodechart) {
        chart.nodes[id] = {
            ...nodechart,
            position: config && config.snapToGrid ? { x: Math.round(data.x / 20) * 20, y: Math.round(data.y / 20) * 20 } : data,
        }
    }

    return chart
}

export const onDragCanvas: IOnDragCanvas = ({ config, event, data }) => (chart: IChart): IChart => {
    chart.offset = config && config.snapToGrid ? { x: Math.round(data.x / 20) * 20, y: Math.round(data.y / 20) * 20 } : data
    return chart
}

export const onLinkStart: IOnLinkStart = ({ linkId, fromNodeId, fromPortId }) => (chart: IChart): IChart => {
    chart.links[linkId] = {
        id: linkId,
        from: {
            nodeId: fromNodeId,
            portId: fromPortId,
        },
        to: {},
    }

    Object.keys(chart.links).forEach((value, index, array) => {

        if (chart.links[value].from.portId === fromPortId) {
            chart.links[value] = {
                id: linkId,
                from: {
                    nodeId: fromNodeId,
                    portId: fromPortId,
                },
                to: {},
            }
        }
    })


    return chart
}

export const onLinkMove: IOnLinkMove = ({ linkId, toPosition }) => (chart: IChart): IChart => {
    const link = chart.links[linkId]
    link.to.position = toPosition
    chart.links[linkId] = { ...link }

    for (let nodesKey in chart.nodes) {
        if (chart.links[linkId].from.nodeId === chart.nodes[nodesKey].id) {
            continue
        }
        chart.nodes[nodesKey].properties.linkHovered = isLinkOverNode(chart, chart.links[linkId], nodesKey)
            ? 'showLinkedNode'
            : '';
    }

    return chart
}

export const onLinkComplete: IOnLinkComplete = (props) => {
    const { linkId, fromNodeId, fromPortId, toNodeId, toPortId, config = {} } = props
    return (chart: IChart): IChart => {
        if (!config.readonly && (config.validateLink ? config.validateLink({ ...props, chart }) : true) && [fromNodeId, fromPortId].join() !== [toNodeId, toPortId].join()) {
            chart.links[linkId].to = {
                nodeId: toNodeId,
                portId: toPortId,
            }
        } else {
            delete chart.links[linkId]
        }
        return chart
    }
}

export const onLinkCancel: IOnLinkCancel = ({ linkId }) => (chart: IChart) => {
    let port = null;

    for (let nodesKey in chart.nodes) {
        chart.nodes[nodesKey].properties.linkHovered = '';
        if (chart.links[linkId].from.nodeId === chart.nodes[nodesKey].id) {
            continue
        }
        if (isLinkOverNode(chart, chart.links[linkId], nodesKey)) {
            for (let portKey in chart.nodes[nodesKey].ports) {
                if (chart.nodes[nodesKey].ports[portKey].type === 'top') {
                    port = chart.nodes[nodesKey].ports[portKey];
                    chart.links[linkId].to = {
                        nodeId: chart.nodes[nodesKey].id,
                        portId: port.id,
                    }
                    return chart;
                }
            }
        }
    }

    delete chart.links[linkId]
    return chart
}

const isLinkOverNode = (chart: IChart, link: ILink, key: string): boolean => {
    // @ts-ignore
    let nodeWidth = chart.nodes[key].size.width;
    // @ts-ignore
    let nodeHeight = chart.nodes[key].size.height;
    // @ts-ignore
    let LinkX = link.to.position.x;
    // @ts-ignore
    let LinkY = link.to.position.y;

    return (chart.nodes[key].position.x < LinkX &&
        chart.nodes[key].position.x + nodeWidth > LinkX &&
        chart.nodes[key].position.y < LinkY &&
        chart.nodes[key].position.y + nodeHeight > LinkY)
}

export const onLinkMouseEnter: IOnLinkMouseEnter = ({ linkId }) => (chart: IChart) => {
    // Set the link to hover
    const link = chart.links[linkId]
    // Set the connected Ports to hover
    if (link.to.nodeId && link.to.portId) {
        if (chart.hovered.type !== 'link' || chart.hovered.id !== linkId) {
            chart.hovered = {
                type: 'link',
                id: linkId,
            }
        }
    }
    return chart
}

export const onLinkMouseLeave: IOnLinkMouseLeave = ({ linkId }) => (chart: IChart) => {
    const link = chart.links[linkId]
    // Set the connected Ports to hover
    if (link.to.nodeId && link.to.portId) {
        chart.hovered = {}
    }
    return chart
}

export const onLinkClick: IOnLinkMouseLeave = ({ linkId }) => (chart: IChart) => {
    if (chart.selected.id !== linkId || chart.selected.type !== 'link') {
        chart.selected = {
            type: 'link',
            id: linkId,
        }
    }
    return chart
}

export const onCanvasClick: IOnCanvasClick = () => (chart: IChart) => {
    if (chart.selected.id) {
        chart.selected = {}
    }
    return chart
}

export const onDeleteKey: IOnDeleteKey = () => (chart: IChart) => {

    if (chart.selected.type === 'node' && chart.selected.id) {
        const node = chart.nodes[chart.selected.id]
        /*
        // Delete the connected links
        Object.keys(chart.links).forEach((linkId) => {
            const link = chart.links[linkId]
            if (link.from.nodeId === node.id || link.to.nodeId === node.id) {
                delete chart.links[link.id]
            }
        })
        // Delete the node
        delete chart.Nodes[chart.selected.id]*/
    } else if (chart.selected.type === 'link' && chart.selected.id) {
        delete chart.links[chart.selected.id]
    }
    if (chart.selected) {
        chart.selected = {}
    }
    return chart
}

export const onNodeClick: IOnNodeClick = ({ nodeId }) => (chart: IChart) => {
    if (chart.selected.id !== nodeId || chart.selected.type !== 'node') {
        chart.selected = {
            type: 'node',
            id: nodeId,
        }
    }
    return chart
}

export const onNodeSizeChange: IOnNodeSizeChange = ({ nodeId, size }) => (chart: IChart) => {
    chart.nodes[nodeId] = {
        ...chart.nodes[nodeId],
        size,
    }
    return chart
}

export const onPortPositionChange: IOnPortPositionChange = ({ node: nodeToUpdate, port, el, nodesEl }) =>
    (chart: IChart): IChart => {
        if (nodeToUpdate.size) {
            // rotate the port's position based on the node's orientation prop (angle)
            const center = { x: nodeToUpdate.size.width / 2, y: nodeToUpdate.size.height / 2 }
            const current = { x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2, y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2 }
            const angle = nodeToUpdate.orientation || 0
            const position = rotate(center, current, angle)

            const node = chart.nodes[nodeToUpdate.id]
            node.ports[port.id].position = {
                x: position.x,
                y: position.y,
            }

            chart.nodes[nodeToUpdate.id] = { ...node }
        }

        return chart
    }

export const onCanvasDrop: IOnCanvasDrop = ({ config, data, position }) => (chart: IChart): IChart => {
    const id = v4()
    chart.nodes[id] = {
        id,
        position: config && config.snapToGrid ? { x: Math.round(position.x / 20) * 20, y: Math.round(position.y / 20) * 20 } : position,
        orientation: data.orientation || 0,
        type: data.type,
        ports: data.ports,
        properties: data.properties,
    }
    return chart
}

const rotate = (center: IPosition, current: IPosition, angle: number): IPosition => {
    const radians = (Math.PI / 180) * angle
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    const x = (cos * (current.x - center.x)) + (sin * (current.y - center.y)) + center.x
    const y = (cos * (current.y - center.y)) - (sin * (current.x - center.x)) + center.y
    return { x, y }
}

import React, {ReactElement} from "react";
import styled from "styled-components";

const HeaderOuter = styled.div`
    background: #656666;
    padding: 10px 20px 8px 10px;
`

export const Header = (): ReactElement => {
    return (
        <HeaderOuter>
            <img src="/logo.png" />
            <img src="/avatar.png" height="40px"  className="fr"/>
        </HeaderOuter>
    )
}
import {IChart} from "@mrblenny/react-flow-chart/src";

export const chart: IChart = {
    offset: {
        x: 0,
        y: 0,
    },
    nodes: {
        start: {
            id: 'start',
            type: 'output-only',
            position: {
                x: 300,
                y: 100,
            },
            properties : {
                value: "",
                type: 'message'
            },
            ports: {
                portTop: {
                    id: 'portTop',
                    type: 'top',
                    properties: {
                        value: 'no',
                    },
                    position: {
                        x:0,
                        y:0,
                    }
                }
            },
        },
    },
    links: {

    },
    selected: {},
    hovered: {},
}
